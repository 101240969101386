import React, { useContext, useState } from "react";
import arrow from "../../../Images/arrow.png";
import searchFilter from "../../../Images/search-filter.png";
import { sortBasedOnKey } from "../../../customFunctions/sortingFunction";
import { makePosition } from "../../../customFunctions/makePosition";
import { DataContext } from "../../../Context/DataProvider";
import searchBlue from "../../../Images/search-blue.png";


const Result = ({ history, setPlayerHistory }) => {
  const [toggleCoachSort, setToggleCoachSort] = useState(true);
  const [toggleTeamSort, setToggleTeamSort] = useState(true);
  const [toggleAgeSort, setToggleAgeSort] = useState(true);
  const [togglePositionSort, setTogglePositionSort] = useState(true);
  const [toggleGamesSort, setToggleGamesSort] = useState(true);
  const [toggleLeagueSort, setToggleLeagueSort] = useState(true);
  const [toggleSeasonSort, setToggleSeasonSort] = useState(true);
  const [toggleGSort, setToggleGSort] = useState(true);
  const [toggleGASort, setToggleGASort] = useState(true);
  const [toggleGDSort, setToggleGDSort] = useState(true);
  const [toggleStartSort, setToggleStartSort] = useState(true);
  const [toggleEndSort, setToggleEndSort] = useState(true);
  const [toggleImpSort, setToggleImpSort] = useState(true);
  const [togglePPTSSort, setTogglePPTSSort] = useState(true);
  const [togglePTSSort, setTogglePTSSort] = useState(true);
  const [toggleDPTSSort, setToggleDPTSSort] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const { base_url, token, user, coachShortListData, setCoachShortListData } =
    useContext(DataContext);

  const ITEMS_PER_PAGE = 10;
  const totalPages = Math.ceil(history?.length / ITEMS_PER_PAGE);

  // Get the current page's data
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = history?.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const handleAddOrRemove = (coach) => {
    console.log(coach);
    if (!checkSaveCoach(coachShortListData, coach?.coach)) {
      fetch(`${base_url}/api/v1/coach/shortlist`, {
        method: "POST",
        body: JSON.stringify({
          user_id: user?.id,
          coach_id: coach?.id,
          name: coach?.coach,
          team: coach?.team,
          delta: coach?.delta?coach.delta:"0",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json?.error?.name[0]==="The name has already been taken.") {
            console.log("coach already taken")
          }else{
            setCoachShortListData([...coachShortListData, json]);
          }
          
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } else {
      const targetedCoach = checkSaveCoach(coachShortListData, coach?.coach);
      console.log(targetedCoach);
      fetch(`${base_url}/api/v1/coach/shortlist/${targetedCoach?.id}`, {
        method: "DELETE",
        body: JSON.stringify({
          user_id: user?.id,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result) {
            const remainingCoach = coachShortListData?.filter((obj) => obj?.id !== targetedCoach?.id);
            
            setCoachShortListData(remainingCoach);
          }
        })
        .catch((error)=>{
          alert("server error")
        })
      
    }
  };

  function checkSaveCoach(arr, searchName) {
    console.log(arr);
    const foundObject = arr.find((item) => item?.name === searchName);
    console.log(foundObject);

    return foundObject;
    
  }


  return (
    <div className=" metric_main_content bg-white overflow-x-scroll">
      <div className="px-6 py-4 border-b">
        <h3 className="font-semibold 2xl:text-base">Results</h3>
      </div>
      <div className="overflow-x-auto pt-4 pr-6 font-semibold metric-table">
        <table className="table w-full">
          {/* head */}
          <thead className="text-neutral-content">
            <tr>
              <th>
                <div
                  onClick={() => {
                    sortBasedOnKey(
                      "coach",
                      toggleCoachSort,
                      history,
                      setPlayerHistory
                    );
                    setToggleCoachSort(!toggleCoachSort);
                  }}
                  className="flex justify-between mt-[37px] cursor-pointer items-center "
                >
                  <p className="pl-6 w-[140px] ">Coach</p>
                </div>
              </th>

              <th>
                <div
                  onClick={() => {
                    sortBasedOnKey(
                      "team",
                      toggleTeamSort,
                      history,
                      setPlayerHistory
                    );
                    setToggleTeamSort(!toggleTeamSort);
                  }}
                  className="flex cursor-pointer mt-[37px] justify-between items-center"
                >
                  <p className="w-[100px]">Team</p>
                </div>
              </th>
              <th>
                <div className="text-center table-top-header-empty"></div>
                <p
                  onClick={() => {
                    sortBasedOnKey(
                      "age",
                      toggleAgeSort,
                      history,
                      setPlayerHistory
                    );
                    setToggleAgeSort(!toggleAgeSort);
                  }}
                  className="w-[48px] min-[1920px]:w-[15px] cursor-pointer text-center"
                >
                  AGE
                </p>
              </th>
              <th>
                <div className="text-center table-top-header-empty"></div>
                <p
                  onClick={() => {
                    sortBasedOnKey(
                      "games",
                      toggleGamesSort,
                      history,
                      setPlayerHistory
                    );
                    setToggleGamesSort(!toggleGamesSort);
                  }}
                  className="w-[64px] min-[1920px]:w-[35px] cursor-pointer text-center"
                >
                  GAMES
                </p>
              </th>

              <th>
                <div className="text-center table-top-header-empty"></div>
                <p
                  onClick={() => {
                    sortBasedOnKey(
                      "season",
                      toggleSeasonSort,
                      history,
                      setPlayerHistory
                    );
                    setToggleSeasonSort(!toggleSeasonSort);
                  }}
                  className="w-[64px] min-[1920px]:w-[40px] cursor-pointer text-center"
                >
                  {" "}
                  SEASON
                </p>
              </th>
              <th>
                <div className="text-center table-top-header-empty"></div>
                <p
                  onClick={() => {
                    sortBasedOnKey(
                      "league_code",
                      toggleLeagueSort,
                      history,
                      setPlayerHistory
                    );
                    setToggleLeagueSort(!toggleLeagueSort);
                  }}
                  className="w-[64px] min-[1920px]:w-[45px] cursor-pointer text-center"
                >
                  LEAGUE
                </p>
              </th>

              <th>
                <div className="text-center table-top-header ml-[65px] min-[1800px]:ml-[60px] ">Goals</div>
                <th>
                  <div className="w-[220px] grid grid-cols-3 gap-3 items-center text-center">
                    <h6
                      onClick={() => {
                        sortBasedOnKey(
                          "off",
                          toggleGSort,
                          history,
                          setPlayerHistory
                        );
                        setToggleGSort(!toggleGSort);
                      }}
                      className="cursor-pointer ml-[72px] min-[1920px]:ml-[80px] "
                    >
                      G
                    </h6>
                    <h6
                      onClick={() => {
                        sortBasedOnKey(
                          "def",
                          toggleGASort,
                          history,
                          setPlayerHistory
                        );
                        setToggleGASort(!toggleGASort);
                      }}
                      className="cursor-pointer ml-[57px] min-[1920px]:ml-[60px]"
                    >
                      GA
                    </h6>
                    <h6
                      onClick={() => {
                        sortBasedOnKey(
                          "total",
                          toggleGDSort,
                          history,
                          setPlayerHistory
                        );
                        setToggleGDSort(!toggleGDSort);
                      }}
                      className="cursor-pointer ml-[32px] min-[1920px]:ml-[40px]"
                    >
                      GD
                    </h6>
                  </div>
                </th>
              </th>
              <th>
                <div className="text-center table-top-header">Points</div>
                <th>
                  <div className="w-[255px]  min-[1644px]:w-[260px] min-[1700px]:w-[265px] min-[1800px]:w-[270px] min-[1900px]:w-[280px] px-[33px] grid grid-cols-3 gap-3 items-center text-center">
                    <h6
                      onClick={() => {
                        sortBasedOnKey(
                          "pPTS",
                          togglePPTSSort,
                          history,
                          setPlayerHistory
                        );
                        setTogglePPTSSort(!togglePPTSSort);
                      }}
                      className=" min-[1700px]:ml-[8px] min-[1800px]:ml-[10px] min-[1900px]:ml-[16px] min-[1920px]:ml-[25px] text-center cursor-pointer >"
                    >
                      pPTS
                    </h6>
                    <h6
                      onClick={() => {
                        sortBasedOnKey(
                          "pts",
                          togglePTSSort,
                          history,
                          setPlayerHistory
                        );
                        setTogglePTSSort(!togglePTSSort);
                      }}
                      className=" min-[1700px]:ml-[-3px] min-[1800px]:mr-[0px] min-[1900px]:ml-[0px] min-[1920px]:ml-[0px] cursor-pointer text-center>"
                    >
                      PTS
                    </h6>
                    <h6
                      onClick={() => {
                        sortBasedOnKey(
                          "dPTS",
                          toggleDPTSSort,
                          history,
                          setPlayerHistory
                        );
                        setToggleDPTSSort(!toggleDPTSSort);
                      }}
                      className="ml-[-8px] min-[1700px]:mr-[10px] min-[1800px]:ml-[-10px] min-[1900px]:ml-[-13px] min-[1920px]:ml-[-12px]  cursor-pointer text-center>"
                    >
                      dPTS
                    </h6>
                  </div>
                </th>
              </th>

              <th>
                <div className="text-center mr-8 table-top-header">
                  Team Rank
                </div>
                <th>
                <div className="ml-1 cursor-pointer min-[1920px]:ml-3 w-[140px] grid grid-cols-3 items-center text-center ">
                  <h6
                    onClick={() => {
                      sortBasedOnKey(
                        "rJ",
                        toggleStartSort,
                        history,
                        setPlayerHistory
                      );
                      setToggleStartSort(!toggleStartSort);
                    }}
                    className=" w-12 ml-[-30px] "
                  >
                    START
                  </h6>
                  <h6
                    onClick={() => {
                      sortBasedOnKey(
                        "rN",
                        toggleEndSort,
                        history,
                        setPlayerHistory
                      );
                      setToggleEndSort(!toggleEndSort);
                    }}
                    className=" cursor-pointer w-12 ml-[-10px] min-[1700px]:ml-[-10px] min-[1800px]:ml-[-10px] min-[1900px]:ml-[-10px] min-[1920px]:ml-[-10px]"
                  >
                    END
                  </h6>
                  <h6
                    onClick={() => {
                      sortBasedOnKey(
                        "delta",
                        toggleImpSort,
                        history,
                        setPlayerHistory
                      );
                      setToggleImpSort(!toggleImpSort);
                    }}
                    className=" cursor-pointer w-12 ml-[10px] min-[1700px]:ml-3  min-[1800px]:ml-2 min-[1900px]:ml-[6px] min-[1920px]:ml-[14px] "
                  >
                    Imp
                  </h6>
                </div>
                </th>
              </th>
            </tr>
          </thead>
          <tbody className="mt-5">
            {currentItems?.map((player, index) => (
              <tr key={index} className="border-b">
                <td>
                  <div className="font-semibold flex gap-2 mr-8 items-center pl-6">
                  {checkSaveCoach(coachShortListData, player?.coach) ? (
                      <img
                        className="cursor-pointer"
                        onClick={() => handleAddOrRemove(player)}
                        src={searchBlue}
                        alt="icon"
                      />
                    ) : (
                      <img
                        className="cursor-pointer"
                        onClick={() => handleAddOrRemove(player)}
                        src={searchFilter}
                        alt="icon"
                      />
                    )}
                    <h4 className="min-[1920px]:w-[150px] min-[1920px]:whitespace-pre-wrap" >{player?.coach}</h4>
                  </div>
                </td>
                <td>
                  <p className="font-medium min-[1920px]:w-[150px] min-[1920px]:whitespace-pre-wrap text-neutral-content">
                    {player?.team}
                  </p>
                </td>

                <td className="font-medium table-text-content text-center">
                  {player?.age > 0 ? player?.age : "-"}
                </td>
                <td className="font-medium table-text-content text-center text-neutral-content">
                  {player?.games}
                </td>
                <td className="font-medium table-text-content text-center text-neutral-content">
                  {player?.season}
                </td>
                <td className="font-medium table-text-content text-center text-neutral-content">
                  {player?.league}
                </td>

                <td className="">
                  <div className="flex justify-around ">
                    <div className="w-[33px] ml-[-2px] flex justify-center items-center">
                      <div className="w-px h-6 border-r"></div>
                    </div>
                    <div className="grid grid-cols-3 ml-1 gap-2 font-semibold text-sm">
                      <div className="w-14 h-8 border rounded flex justify-center items-center">
                        {player?.off ? Number(player.off).toFixed(2) : "0.00"}
                      </div>
                      <div className="w-14 h-8 border rounded flex justify-center items-center">
                        {player?.def ? Number(player.def).toFixed(2) : "0.00"}
                      </div>
                      <div
                        className={`w-14 h-8 rounded flex justify-center my-border items-center ${
                          Number(player?.total) < 0
                            ? "box-border-red"
                            : Number(player?.total).toFixed(2) > 0.0
                            ? "box-border-green"
                            : "box-border-light"
                        } `}
                      >
                        {player?.total ? +Number(player.total).toFixed(2) : "0.00"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-around ">
                    <div className="w-[33px] ml-[-2px] flex justify-center items-center">
                      <div className="w-px h-6 border-r"></div>
                    </div>
                    <div className="grid grid-cols-3 gap-2 ml-[-13px] font-semibold text-sm">
                      <div className="bg-gray-100 w-11 ml-3 h-[28px] rounded flex justify-center items-center">
                        {Number(player?.pPTS).toFixed(1)}
                      </div>
                      <div className="bg-gray-100 w-11 ml-3 h-[28px] rounded flex justify-center items-center">
                        {Number(player?.pts).toFixed(1)}
                      </div>
                      <div className="bg-gray-100 w-11 ml-3 h-[28px] rounded flex justify-center items-center">
                        {player?.dPTS === "0"
                          ? "-"
                          : Number(player?.dPTS).toFixed(1)}
                      </div>
                    </div>
                    <div className="w-[33px] flex justify-center items-center">
                      <div className="w-px h-6 border-r"></div>
                    </div>
                  </div>
                </td>

                <td>
                  <div className=" ml-1 min-[1920px]:ml-3 grid grid-cols-3 table-text-right items-center text-center">
                    <div className=" bg-gray-100 w-12 h-[28px] rounded ">
                      {makePosition(player?.rJ)}
                    </div>

                    <div className="bg-gray-100 w-12 h-[28px] rounded ">
                      {makePosition(player?.rN)}
                    </div>
                    <div
                      className={` ${
                        Number(player?.delta) > 0.49
                          ? "coach-metric-table-value-last"
                          : Number(player?.delta) < 0
                          ? "text-red-700"
                          : ""
                      } bg-gray-100 w-12 h-[28px] rounded `}
                    >
                      {player?.delta > 0.49 && "+"}
                      {Math.round(Number(player?.delta))}%
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Footer */}
      <div className="mt-4 mb-2 text-center">
        <div>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              className={`w-8 mx-1 h-8 text-neutral-content  border rounded-md ${
                currentPage === index + 1 && "active-page"
              } `}
              key={index}
              onClick={() => handlePageChange(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Result;
