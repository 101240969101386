import React, { useContext, useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import player from "../../Images/sidebar-player.png";
import League from "./Dropdown/League";
import circle from "../../Images/point-circle.png";
import searchBlue from "../../Images/search-blue.png";
import searchFilter from "../../Images/search-filter.png";
import Season from "./Dropdown/Season";
import Position from "./Dropdown/Position";
import Team from "./Dropdown/Team";
import ProgressBar from "./ProgressBar";
import { DataContext } from "../../Context/DataProvider";

const Sidebar = ({
  playerInfo,
  content,
  debounceOnChange,
  positionSelected,
  setPositionSelected,
  seasonSelected,
  setSeasonSelected,
  leagueSelected,
  setLeagueSelected,
  teamSelected,
  setTeamSelected,
  finalData,
  setFirstClick,
  setPlayerInfo,
  positionData,
}) => {
  const { base_url, token, user, playerShortListData, setPlayerShortListData } =
    useContext(DataContext);

  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (75 / 100) * circumference;

  const handleAddOrRemove = (player) => {
    if (!checkSavePlayer(playerShortListData, player?.name_dob)) {
      fetch(`${base_url}/api/v1/player/shortlist`, {
        method: "POST",
        body: JSON.stringify({
          user_id: user?.id,
          player_id: player?.id,
          name_dob: player?.name_dob,
          name: player?.name,
          team: player?.team,
          position: player?.position,
          x_gdar: player?.x_gdar ? player.x_gdar : "0",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json) {
            setPlayerShortListData([...playerShortListData, json]);
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } else {
      const targetedPlayer = checkSavePlayer(
        playerShortListData,
        player?.name_dob
      );
      fetch(`${base_url}/api/v1/player/shortlist/${targetedPlayer?.id}`, {
        method: "DELETE",
        body: JSON.stringify({
          user_id: user?.id,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result) {
            const remainingPlayers = playerShortListData?.filter(
              (obj) => obj?.id !== targetedPlayer?.id
            );
            // const targetedPlayer = data?.find((obj) => obj?.id === id);
            // const filteredPlayer = playerShortListData?.filter(obj=>obj?.id!==targetedPlayer?.id);
            // setData(remainingPlayers);
            setPlayerShortListData(remainingPlayers);
          }
        })

        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    }
  };

  function checkSavePlayer(arr, searchName) {
    const foundObject = arr.find((item) => item?.name_dob === searchName);
    return foundObject;
  }

  return (
    <div className="sidebar">
      <div className="w-full search-box relative text-sm mb-4 lg:mb-0">
        <input
          type="text"
          name=""
          // value={searchedText}
          onChange={debounceOnChange}
          className="w-full input-border p-3 pl-10 rounded-lg outline-none"
          placeholder="Search for a player"
        />

        <div className="absolute top-4 left-4 text-lg">
          <BiSearch />
        </div>
      </div>
      {content}
      {playerInfo?.name_dob && playerInfo && (
        <div className="sidebar-content rounded-lg shadow-lg bg-white mt-3 p-5 max-[1600px]:px-3.5 ">
          <div className="profile flex items-center gap-3">
            <div>
              <img
                className="w-[64px] border rounded-full"
                src={`${base_url}/api/v1/player/image/${playerInfo?.name_dob}`}
                alt="player"
              />
            </div>
            <div className="right w-full">
              <div className="flex gap-1 items-center ">
                <div className="basis-[90%]" >
                <h4>{playerInfo?.name}</h4>
                </div>

                <div className="basis-[10%]" >
                  {checkSavePlayer(
                    playerShortListData,
                    playerInfo?.name_dob
                  ) ? (
                    <img
                      className="cursor-pointer mt-2"
                      onClick={() => handleAddOrRemove(playerInfo)}
                      src={searchBlue}
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="cursor-pointer mt-2"
                      onClick={() => handleAddOrRemove(playerInfo)}
                      src={searchFilter}
                      alt="icon"
                    />
                  )}
                </div>
              </div>

              <p>{playerInfo?.team}</p>
            </div>
          </div>
          <div className="traits flex gap-1 mt-5">
            {playerInfo?.hot > 0 && <div className="green">High Performer</div>}
            {playerInfo?.cold > 0 && <div className=" red">Low Performer</div>}

            {playerInfo?.prospect > 0 && (
              <div className="green">Young Talent</div>
            )}
            {playerInfo?.underused > 0 && (
              <div className="w-24 h-7 medium rounded whitespace-nowrap">
                Underused
              </div>
            )}
            {playerInfo?.breakout > 0 && (
              <div className="w-24 h-7 medium rounded whitespace-nowrap">
                First Season
              </div>
            )}
          </div>
          <div className="overview mt-3">
            <h4 className="text-1 mb-3">OVERVIEW</h4>

            <Season
              position={positionSelected}
              seasonSelected={seasonSelected}
              setSeasonSelected={setSeasonSelected}
              // new
              setPositionSelected={setPositionSelected}
              setLeagueSelected={setLeagueSelected}
              setTeamSelected={setTeamSelected}
              finalData={finalData}
              setFirstClick={setFirstClick}
              setPlayerInfo={setPlayerInfo}
            />
            <Position
              positionSelected={positionSelected}
              setPositionSelected={setPositionSelected}
              playerInfo={playerInfo}
              // new
              season={seasonSelected}
              seasonSelected={seasonSelected}
              setLeagueSelected={setLeagueSelected}
              setTeamSelected={setTeamSelected}
              finalData={finalData}
              setFirstClick={setFirstClick}
              setPlayerInfo={setPlayerInfo}
            />
            <League
              // playerName={playerName}
              leagueSelected={leagueSelected}
              setLeagueSelected={setLeagueSelected}
              position={positionSelected}
              season={seasonSelected}
              // new
              setTeamSelected={setTeamSelected}
              finalData={finalData}
              setFirstClick={setFirstClick}
              setPlayerInfo={setPlayerInfo}
            />
            <Team
              teamSelected={teamSelected}
              setTeamSelected={setTeamSelected}
              position={positionSelected}
              season={seasonSelected}
              league={leagueSelected}
              finalData={finalData}
              setFirstClick={setFirstClick}
              setPlayerInfo={setPlayerInfo}
            />
          </div>
          <div className="details mt-3">
            <h4 className="text-1 mb-3">DETAILS</h4>
            <div className="grid grid-cols-2 gap-3 ">
              <div className="box">
                <div className="key">Age</div>
                <div className="value">
                  {playerInfo.age === "0" ? "-" : playerInfo.age}
                </div>
              </div>
              <div className="box">
                <div className="key">Height</div>
                <div className="value">
                  {playerInfo.height === "0" || !playerInfo.height ? "--" : playerInfo.height + "cm"}
                </div>
              </div>
              <div className="box">
                <div className="key">Minutes</div>
                <div className="value">
                  {playerInfo.minutes === 0 ? "--" : playerInfo.minutes}
                </div>
              </div>
              <div className="box">
                <div className="key">Foot</div>
                <div className="value">
                  {playerInfo?.foot ? playerInfo.foot : "--"}
                </div>
              </div>
            </div>
          </div>
          <div className="position mt-3">
            <h4 className="text-1">POSITION</h4>
            {positionData?.map((data) => (
              <div className="box mt-3">
                <div className="left flex gap-4 items-center ">
                  <div className="pos-box">
                    <div className="pos">
                      <div className="position-title text-center">
                        {data?.position}
                      </div>
                    </div>
                  </div>
                  <div className="game">
                    <div className="font-bol text-[#22272F] ">
                      {data?.position === "CF/ST" && "Centre Forward"}
                      {data?.position === "LW" && "Left Wing"}
                      {data?.position === "RW" && "Right Wing"}
                      {data?.position === "LWB/LM" && "Left Midfield"}
                      {data?.position === "RWB/RM " && "Right Midfield"}
                      {data?.position === "CM" && "Centre Midfield"}
                      {data?.position === "DM" && "Defensive Midfield"}
                      {data?.position === "CAM/SS" && "Attacking Midfield"}
                      {data?.position === "LB" && "Left Back"}
                      {data?.position === "RB" && "Right Back"}
                      {data?.position === "LCB" && "L. Centre Back"}
                      {data?.position === "RCB" && "R. Centre Back"}
                      {data?.position === "CCB" && "C. Centre Back"}
                      {data?.position === "GK" && "Goal-Keeper"}
                    </div>
                    <div className="text-[#9299AA]">
                      {data?.career_games_in_position} out of{" "}
                      {data?.career_games} games
                    </div>
                  </div>
                  <div className="right">
                    <div className="w-[40px] h-[40px] ">
                      <ProgressBar
                        progress={data?.career_games_in_position_percentage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
