import React, { useContext, useState } from "react";
import profileImage from "../../Images/player-profile.png";
import teamImage from "../../Images/player-team.png";
import playerTrophy from "../../Images/player-trophy.png";
import playerStat from "../../Images/player-stat.png";
import infoIcon from "../../Images/info-icon.png";
import HistoryTable from "./HistoryTable";
import ProgressBar from "../PlayerProfile/ProgressBar";
import { DataContext } from "../../Context/DataProvider";
import { formateDate } from "../../customFunctions/formateDate";
import { makePosition } from "../../customFunctions/makePosition";

const playersViaLeague = [
  {
    img: profileImage,
    name: "A. Robertson",
    team: "Real Madrid",
    points: "85.2 GIR",
  },
  {
    img: profileImage,
    name: "A. Davies",
    team: "Barcelona",
    points: "81.1 GIR",
  },
  {
    img: profileImage,
    name: "A. Robertson",
    team: "Real Madrid",
    points: "85.2 GIR",
  },
  {
    img: profileImage,
    name: "A. Davies",
    team: "Barcelona",
    points: "81.1 GIR",
  },
  {
    img: profileImage,
    name: "A. Robertson",
    team: "Real Madrid",
    points: "85.2 GIR",
  },
  {
    img: profileImage,
    name: "A. Davies",
    team: "Barcelona",
    points: "81.1 GIR",
  },
  {
    img: profileImage,
    name: "A. Robertson",
    team: "Real Madrid",
    points: "85.2 GIR",
  },
  {
    img: profileImage,
    name: "A. Davies",
    team: "Barcelona",
    points: "81.1 GIR",
  },
  {
    img: profileImage,
    name: "A. Robertson",
    team: "Real Madrid",
    points: "85.2 GIR",
  },
  {
    img: profileImage,
    name: "A. Davies",
    team: "Barcelona",
    points: "81.1 GIR",
  },
];

const ProfileInfo = ({
  playerInfo,
  playerDetails,
  positionData,
  leagueData,
  selectedSeason,
  selectedPosition,
  selectedLeague,
}) => {
  const { base_url, token, user, playerShortListData, setPlayerShortListData } =
    useContext(DataContext);
  const [currentPage, setCurrentPage] = useState(1);

  // pagination for players via league
  const ITEMS_PER_PAGE = 7;
  const totalPages = Math.ceil(leagueData?.length / ITEMS_PER_PAGE);

  // Get the current page's data
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = leagueData?.slice(indexOfFirstItem, indexOfLastItem) ;

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let positionsForUI = [];
  [...positionData]?.forEach((data) => {
    if (data?.career_games_in_position_percentage > 10) {
      positionsForUI.push(data?.position);
    }
  });
  console.log(playerInfo);
  return (
    <div className="p-3 md:p-6 md:pb-3 mt-6 bg-white shadow-lg rounded-[8px] ">
      {/* profile name , image */}
      <div className="flex justify-between items-center ">
        <div className="flex gap-4 items-center ">
          <div className="">
            <img
              className="w-[64px] border rounded-full"
              src={`${base_url}/api/v1/player/image/${playerInfo?.name_dob}`}
              alt="profile"
            />
          </div>
          <div>
            <h4 className="text-[#22272F] text-base md:text-lg font-semibold ">
              {playerInfo?.name}
            </h4>
            <p className="mt-1 text-sm md:text-base font-medium text-[#9299AA] ">
              Born {formateDate(playerInfo?.birthdate)} (Aged {playerInfo?.age})
            </p>
          </div>
        </div>
        <div>
          <img
            className="w-10 h-10 2xl:w-[44px] 2xl:h-[44px] "
            src={`${base_url}/api/v1/team/image/${playerInfo?.team}`}
            alt="team"
          />
        </div>
      </div>

      {/* sec-2 trait */}

      <div className="traits flex gap-1 mt-5">
        {playerInfo?.hot > 0 && (
          <div className="text-[#02B059] text-sm font-semibold px-2 py-1 rounded-[4px] bg-[#02B05923] ">
            High Performer
          </div>
        )}
        {playerInfo?.cold > 0 && (
          <div className="text-[#FA4265] text-sm font-semibold px-2 py-1 rounded-[4px] bg-[#FA426523] ">
            Low Performer
          </div>
        )}

        {playerInfo?.prospect > 0 && (
          <div className="text-[#02B059] text-sm font-semibold px-2 py-1 rounded-[4px] bg-[#02B05923] ">
            Young Talent
          </div>
        )}
        {playerInfo?.underused > 0 && (
          <div className="text-[#F29445] text-sm font-semibold px-2 py-1 rounded-[4px] bg-[#F2944523] ">
            Underused
          </div>
        )}
        {playerInfo?.breakout > 0 && (
          <div className="text-[#F29445] text-sm font-semibold px-2 py-1 rounded-[4px] bg-[#F2944523] ">
            First Season
          </div>
        )}
      </div>

      {/* sec-3 ht, min, age */}
      <div className="mt-4 grid grid-cols-2 md:flex gap-8 ">
        <div className="flex flex-col ">
          <h4 className="text-[#9299AA] text-sm md:text-base font-medium ">
            Height
          </h4>
          <h4 className="text-[#22272F] font-semibold text-sm md:text-base ">
            {playerInfo?.height === "0" || !playerInfo?.height ? (
              <span className="ml-3">--</span>
            ) : (
              playerInfo?.height + "cm"
            )}
          </h4>
        </div>
        <div className="flex flex-col ">
          <h4 className="text-[#9299AA] text-sm md:text-base font-medium ">
            Minutes
          </h4>
          <h4 className="text-[#22272F] font-semibold text-sm md:text-base ">
            {playerInfo?.minutes === 0 ? (
              <span className="ml-3">--</span>
            ) : (
              playerInfo?.minutes
            )}
          </h4>
        </div>
        <div className="flex flex-col ">
          <h4 className="text-[#9299AA] text-sm md:text-base font-medium ">
            Foot
          </h4>
          <h4 className="text-[#22272F] font-semibold text-sm md:text-base ">
            {playerInfo?.foot ? (
              playerInfo?.foot
            ) : (
              <span className="ml-2">--</span>
            )}
          </h4>
        </div>
        <div className="flex flex-col ">
          <div className="flex items-center gap-3 justify-between ">
            <h4 className="text-[#9299AA] text-sm md:text-base font-medium ">
              Position
            </h4>
            <label
              htmlFor="player-position"
              className=" cursor-pointer p-1 rounded border-[1.5px] border-[#7C86A129] "
            >
              <img src={infoIcon} alt="info-icon" />
            </label>
          </div>
          <h4 className="text-[#22272F] font-semibold text-sm md:text-base ">
            {positionsForUI?.join(", ")}
          </h4>
        </div>
      </div>

      {/* sec-4 position and rank */}
      <div className="mt-6 flex flex-col md:flex-row  gap-3 min-[1880px]:gap-6 ">
        <div className="flex  basis-full md:basis-[40%]  gap-3 min-[1880px]:gap-6 ">
          <div className="basis-full rounded-[8px] hidden md:flex justify-between md:justify-center items-center flex-row md:flex-col gap-1  border-[1.5px] border-[#7C86A149] p-4  bg-white shadow-lg ">
            <div className="shadow-lg mb-2 w-10 h-10 bg-white border-[1.5px] border-[#7C86A149] flex justify-center items-center rounded-[5px] ">
              <img src={playerTrophy} alt="trophy" />
            </div>
            <div className="text-[#22272F] text-[32px] font-semibold ">
              {playerInfo?.position_rank
                ? makePosition(playerInfo?.position_rank)
                : "-"}
            </div>
            <div className="text-[#9299AA] text-base font-medium ">
              out of{" "}
              {playerInfo?.position_rank_max
                ? " " + playerInfo?.position_rank_max + " "
                : " - "}
              in{" "}
              <label onClick={()=>setCurrentPage(1)} className="cursor-pointer" htmlFor="players-modal">
                <span className=" underline text-primary">
                  {playerInfo?.league_code}
                </span>
              </label>
            </div>
          </div>

          {/* for mobile */}
          <div className="basis-full rounded-[8px] flex md:hidden justify-between md:justify-center items-center flex-row md:flex-col gap-1  border-[1.5px] border-[#7C86A149] p-3  bg-white shadow-lg ">
            <div className="flex items-center gap-3  ">
              <div className="shadow-lg w-10 h-10 bg-white border-[1.5px] border-[#7C86A149] flex justify-center items-center rounded-[5px] ">
                <img src={playerTrophy} alt="trophy" />
              </div>
              <div className="text-[#22272F] text-[21px] font-semibold ">
                {playerInfo?.position_rank
                  ? makePosition(playerInfo?.position_rank)
                  : "-"}
              </div>
            </div>
            <div className="text-[#9299AA] text-sm md:text-base font-medium ">
              out of{" "}
              {playerInfo?.position_rank_max
                ? " " + playerInfo?.position_rank_max + " "
                : " - "}
              in{" "}
              <label className="cursor-pointer" htmlFor="players-modal">
                <span className=" underline text-primary">
                  {playerInfo?.league_code}
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className=" basis-full md:basis-[60%] bg-white border-[1.5px] border-[#7C86A149] shadow-lg p-3 md:p-4 rounded-[8px]  ">
          <div className="mt-3 flex justify-between ">
            <div className="basis-[65%] flex gap-2 items-center ">
              <div className="flex items-center gap-[2px] ">
                <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                <div className="rounded-full h-[6px] w-[6px] bg-[#9299AA7A] "></div>
              </div>
              <div className="text-[#9299AA] text-sm md:text-xs min-[1700px]:text-sm font-medium ">
                Attacking Rating
              </div>
            </div>
            <div className="text-[#22272F] flex justify-between gap-1 font-semibold text-sm md:text-xs min-[1700px]:text-sm ">
              <span>
                {playerInfo?.gd_rank_in_team_off
                  ? makePosition(Number(playerInfo?.gd_rank_in_team_off))
                  : "-"}
              </span>
              <span className="font-normal">out of</span>
              <span>
                {playerInfo?.position_rank_max
                  ? playerInfo.position_rank_max
                  : "-"}
              </span>
            </div>
          </div>
          <div className="py-3 mt-3 border-y-[1.5px] border-y-[#F2F3F6] flex justify-between ">
            <div className="basis-[65%] flex gap-2 items-center ">
              <div className="flex items-center gap-[2px] ">
                <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                <div className="rounded-full h-[6px] w-[6px] bg-[#9299AA7A] "></div>
              </div>
              <div className="text-[#9299AA] text-sm md:text-xs min-[1700px]:text-sm font-medium ">
                Defending Rating
              </div>
            </div>
            <div className="text-[#22272F] flex gap-1 justify-between font-semibold text-sm md:text-xs min-[1700px]:text-sm ">
              <span>
                {playerInfo?.gd_rank_in_team_def
                  ? makePosition(Number(playerInfo?.gd_rank_in_team_def))
                  : "-"}
              </span>
              <span className="font-normal">out of</span>
              <span>
                {playerInfo?.position_rank_max
                  ? playerInfo.position_rank_max
                  : "-"}
              </span>
            </div>
          </div>
          <div className="mt-3 flex justify-between ">
            <div className="basis-[65%] flex gap-2 items-center ">
              <div className="flex items-center gap-[2px] ">
                <div className="rounded-full h-[6px] w-[6px] bg-[#F29445] "></div>
                <div className="rounded-full h-[6px] w-[6px] bg-[#F29445] "></div>
                <div className="rounded-full h-[6px] w-[6px] bg-[#F29445] "></div>
                <div className="rounded-full h-[6px] w-[6px] bg-[#F29445] "></div>
                <div className="rounded-full h-[6px] w-[6px] bg-[#9299AA7A] "></div>
              </div>
              <div className="text-[#9299AA] text-sm md:text-xs min-[1700px]:text-sm font-medium ">
                Possession Rating
              </div>
            </div>
            <div className="text-[#22272F] flex justify-between gap-1 font-semibold text-sm md:text-xs min-[1700px]:text-sm ">
              <span>
                {playerInfo?.ball_ret_rank
                  ? makePosition(Number(playerInfo?.ball_ret_rank))
                  : "-"}
              </span>
              <span className="font-normal">out of</span>{" "}
              <span>
                {playerInfo?.position_rank_max
                  ? playerInfo.position_rank_max
                  : "-"}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* sec-5 history table */}
      <HistoryTable playerDetails={playerDetails} />

      {/* positions modal */}
      <div>
        <input type="checkbox" id="player-position" className="modal-toggle" />
        <div className="modal">
          <div className="modal-box max-w-sm player-position p-0">
            <div className="player-position-header flex justify-between items-center p-5">
              <h3 className="font-bold text-[#7C86A1] text-xs">POSITION</h3>
              <label
                htmlFor="player-position"
                className="h-[25px] w-[25px] rounded-full flex cursor-pointer justify-center items-center bg-white shadow-lg text-sm border font-semibold "
              >
                <div className="mt-[-2px] ml-px ">x</div>
              </label>
            </div>
            <div className="px-5 flex flex-col pb-2">
              {positionData?.map((data, index) => (
                <div
                  key={index}
                  className={`flex py-3 ${
                    index !== positionData?.length - 1 && "border-b"
                  }  items-center justify-between`}
                >
                  <div className="flex items-center gap-4">
                    <div className="w-[80px]">
                      <div className="text-[#22272F] inline rounded p-2 text-xs font-semibold bg-[#F7F7F8] ">
                        {data?.position}
                      </div>
                    </div>
                    <div className="">
                      <div className="font-semibold text-sm text-[#22272F] ">
                        {data?.position === "CF/ST" && "Centre Forward"}
                        {data?.position === "LW" && "Left Wing"}
                        {data?.position === "RW" && "Right Wing"}
                        {data?.position === "LWB/LM" && "Left Midfield"}
                        {data?.position === "RM/RWB" && "Right Midfield"}
                        {data?.position === "CM" && "Centre Midfield"}
                        {data?.position === "DM" && "Defensive Midfield"}
                        {data?.position === "CAM/SS" && "Attacking Midfield"}
                        {data?.position === "LB" && "Left Back"}
                        {data?.position === "RB" && "Right Back"}
                        {data?.position === "LCB" && "L. Centre Back"}
                        {data?.position === "RCB" && "R. Centre Back"}
                        {data?.position === "CCB" && "C. Centre Back"}
                        {data?.position === "GK" && "Goal-Keeper"}
                      </div>
                      <div className="text-[#9299AA] font-medium text-sm ">
                        {data?.career_games_in_position} out of{" "}
                        {data?.career_games} games
                      </div>
                    </div>
                  </div>
                  <div className="w-[40px] h-[40px] ">
                    <ProgressBar
                      progress={data?.career_games_in_position_percentage}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* players via league modal */}
      <div className="">
        <input type="checkbox" id="players-modal" className="modal-toggle" />
        <div className="modal players-table-profile">
          <div className="modal-box players-modal p-0">
            <div className="players-modal-header flex justify-between items-center border-b p-5 pb-4">
              <h3 className="font-bold text-lg">
                {selectedPosition} in{" "}
                <span className="uppercase">{playerInfo?.league_code}</span>{" "}
              </h3>
              <label
                htmlFor="players-modal"
                className="h-[25px] w-[25px] mt-[-4px] rounded-full flex cursor-pointer justify-center items-center bg-white shadow-lg text-sm border font-semibold "
              >
                <div className=" ml-px ">x</div>
              </label>
            </div>
            <div className="px-2 pt-3 md:px-5">
              <table className="players-modal-table w-full ">
                <tbody>
                  {currentItems?.map((player, index) => (
                    <tr
                      className={` ${
                        (index + 1) % 2 !== 0 ? "bg-[#f2f3f6]  " : ""
                      }`}
                      key={index}
                    >
                      <td className="rounded-l-lg w-[4%] ">
                        <div className="pl-2 pr-1">
                          {player?.position_rank}.
                        </div>
                      </td>
                      <td>
                        <img
                          src={`${base_url}/api/v1/player/image/${player?.name_dob}`}
                          className=" w-[25px] md:w-[30px] h-[25px] md:h-[30px] rounded-full "
                          alt="player"
                        />
                      </td>
                      <td className="w-[35%]">
                        <div className=" whitespace-normal ">
                          {player?.name?.split(' ')?.length>2 ? (player?.name?.split(' ')[0] + " " + player?.name?.split(' ')[player?.name?.split(' ')?.length-1]) : player?.name}
                        </div>
                      </td>
                      <td className="text-right ">{player?.team?.split(' ')?.length>2 ? (player?.team?.split(' ')[0] + " " + player?.team?.split(' ')[player?.team?.split(' ')?.length-1]) : player?.team}</td>
                      <td className="rounded-r-lg">
                        <div className="flex justify-center ">
                          <div className="pl-4 pr-2">{player?.x_gdar}</div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {totalPages > 1 ? (
              <div className="border-t mt-2 py-4 flex justify-center ">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    className={`w-8 h-8 mx-1 mb-2 md:mb-0 text-neutral-content  border rounded-md ${
                      currentPage === index + 1 && "active-page"
                    } `}
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    disabled={currentPage === index + 1}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
