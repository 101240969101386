import React, { useContext, useEffect, useState } from "react";
import CreatePlayerModal from "./CreatePlayerModal";
import { DataContext } from "../../../Context/DataProvider";
import { useParams } from "react-router-dom";
import "../FinancePlayerInfo/PlayerInfoTable.css";

const benchPlayers = [
  {
    number: "P1",
    name: "Luke Shaw",
    jerseyNumber: "23",
    goal: "75",
    color: "#02B059",
  },
  {
    number: "P2",
    name: "Scott Mc Tominay",
    jerseyNumber: "39",
    goal: "75",
    color: "#02B059",
  },
  {
    number: "P3",
    name: "Bruno Fernandes",
    jerseyNumber: "75",
    goal: "75",
    color: "#02B059",
  },
  {
    number: "P4",
    name: "Kevin De Bruyne",
    jerseyNumber: "78",
    goal: "75",
    color: "#02B059",
  },
  {
    number: "P5",
    name: "Bernardo Silva",
    jerseyNumber: "85",
    goal: "75",
    color: "#02B059",
  },
  {
    number: "P6",
    name: "Erling Haaland",
    jerseyNumber: "43",
    goal: "75",
    color: "#02B059",
  },
];

// console.log(getPlayerForPosition("USA","3_1_GK", fakeData)) ;

const TeamMembers = ({ data, pitchData }) => {
  const { assignedPlayers } = useContext(DataContext);
  const { team } = useParams();
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [id, setId] = useState(null);
  const [playerType, setPlayerType] = useState(null);
  const [benchId, setBenchId] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  function getPlayerForPosition(teamName, id, data) {
    const slTeam = data?.find((item) => item?.team === teamName);
    return slTeam?.players?.find((item) => item?.id === id);
  }

  function getBenchPlayer(teamName, id, data) {
    const slTeam = data?.find((item) => item?.team === teamName);
    return slTeam?.benches?.find((item) => item?.id === id);
  }

  console.log("assign players", assignedPlayers);

  return (
    <div className="w-2/5">
      <div className="bg-base-100 rounded-lg">
        <div className="px-6 py-3.5 flex justify-between items-center">
          <h6 className="font-semibold 2xl:text-base">
            Fenida FC Squad Breakdown
          </h6>
          <div className="relative">
            <button className="w-full input-border px-3 py-2 rounded-lg outline-none flex gap-[9px] items-center bg-base-100">
              <p className="text-[13px] text-[#22272F] 2xl:text-sm font-semibold ">
                Manage
              </p>
            </button>
          </div>
        </div>
        <div className="">
          <table className=" finance-table squad-table">
            <thead>
              <tr>
                <th colSpan="2">Starting 11</th>
                <th>Contract</th>
                <th>Cost PW</th>
                <th>Cost PA</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 11 }).map((_, index) => (
                <tr key={index}>
                  <td>
                  {index + 1}
                  </td>
                  <td>Search Player</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              ))}

              <tr>
                <th colSpan="2">Matchday Bench</th>
                <th>Contract</th>
                <th>Cost PW</th>
                <th>Cost PA</th>
              </tr>
              {Array.from({ length: 9 }).map((_, index) => (
                <tr key={index}>
                  <td>
                  {12 + index}
                  </td>
                  <td>Search Player</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              ))}
              <tr>
                <th colSpan="2">Squad Players</th>
                <th>Contract</th>
                <th>Cost PW</th>
                <th>Cost PA</th>
              </tr>
              {Array.from({ length: 4 }).map((_, index) => (
                <tr key={index}>
                  <td>
                    {21 + index}
                  </td>
                  <td>Search Player</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <CreatePlayerModal
        selectedPlayer={selectedPlayer}
        playerType={playerType}
        id={id}
        benchId={benchId}
        position={selectedPosition}
      />
    </div>
  );
};

export default TeamMembers;
