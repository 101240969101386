import React, { useContext } from "react";
import "./Pitch.css";
import { DataContext } from "../../../Context/DataProvider";
import { useParams } from "react-router-dom";


const Pitch = ({pitchData}) => {
  const { assignedPlayers, setAssignedPlayers } =
    useContext(DataContext);

    const { team } = useParams();

  function getPlayerForPosition(team, id, data){
    const slTeam = data?.find(item=>item?.team===team);
     return slTeam?.players?.find(item=>item?.id===id);
  }

  return (
    <div class="soccer-field relative">
      <div class="line"></div>
      <div class="half"></div>
      {
        pitchData?.map((data,index)=><div style={{position:"absolute",top:`${data?.top}%`, left:`${data?.left}%`, zIndex:"10", transform:"translate(-50%,-50%)" }} key={index} className={`flex justify-center flex-col items-center`} >
          <div className="flex justify-center   rounded-full w-[40px] h-[40px] text-white bg-[#2C56EB] items-center" >
            {data?.position_abbreviation}
          </div>
          <div className={`${getPlayerForPosition(team,data?.formation_id+"_"+data?.position_id+"_"+data?.position_abbreviation,assignedPlayers)&&"bg-[#F1F1F3]"} mt-1 rounded text-sm font-semibold p-1 whitespace-nowrap text-[#22272F]`}>
          {
            getPlayerForPosition(team,data?.formation_id+"_"+data?.position_id+"_"+data?.position_abbreviation,assignedPlayers)?getPlayerForPosition(team,data?.formation_id+"_"+data?.position_id+"_"+data?.position_abbreviation,assignedPlayers).playerName.split('(')[0]:""
          }
         </div>
         
        </div>)
      }
      <div class="panelty left"></div>
      <div class="panelty right"></div>
      <div class="p-spot left">&nbsp;</div>
      <div class="p-spot right">&nbsp;</div>
      <div class="center"></div>
      <div class="p-place left"></div>
      <div class="p-place right"></div>
    </div>
  );
};

export default Pitch;
