import React, { useEffect, useState } from "react";

const allSeasons = [
  "2024-25",
  "2023-24",
  "2022-23",
  "2021-22",
  "2020-21",
  "2019-20",
  "2018-19",
  "2017-18",
  "2016-17",
];

const Seasons = ({
  seasonSelected,
  setSeasonSelected,
  selectedSeasons,
  setSelectedSeasons,
  setPage,
}) => {
  const [seasonToggle, setSeasonToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const handleSeasonSelected = (season) => {
    setLoading(false);
    const seasons = [...selectedSeasons, season];
    setSelectedSeasons([...new Set(seasons)]);
    setSeasonSelected(season);
    setSeasonSelected("select season");
    setIsLoad(false);
    setPage(1);
  };

  const handleRemoveSeason = (season) => {
    const filteredSeasons = selectedSeasons.filter((item) => item !== season);
    setSelectedSeasons(filteredSeasons);
    setSeasonSelected("select season");
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        // !e.target.closest(".season-dropdown") &&
        !e.target.closest(".season-btn")
      ) {
        setSeasonToggle(false);
      }
    });
  }, []);

  return (
    <div className="mt-3 ">
      <h6 className="text-sm" >Season</h6>
      <div className=" flex flex-wrap gap-1 ">
        {selectedSeasons.length > 0 &&
          selectedSeasons.map((season, index) => (
            <div
              key={index}
              style={{ fontSize: "11px" }}
              className="p-1 flex gap-1 justify-between items-center text-sm border rounded-e"
            >
              <div>{season}</div>
              <div
                onClick={() => handleRemoveSeason(season)}
                className="border cursor-pointer rounded-full px-1 bg-white shadow-sm"
              >
                x
              </div>
            </div>
          ))}
      </div>
      <div className="relative mt-2">
        <button
          onClick={() => setSeasonToggle(!seasonToggle)}
          className="w-full xl:w-full capitalize input-border p-2 rounded-lg outline-none season-btn flex justify-between items-center text-sm bg-base-100"
        >
          {seasonSelected}
        </button>

        {seasonToggle && (
          <div className="absolute max-h-[300px] overflow-y-scroll season-dropdown w-full bg-base-100 shadow-lg mt-1 rounded-lg  z-50 border">
            {allSeasons.map((item, index) => (
              <p
                key={index}
                onClick={()=>handleSeasonSelected(item)}
                className="cursor-pointer text-sm p-1.5 hover:bg-[#F7F7F8] duration-300"
              >
                {item}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Seasons;
