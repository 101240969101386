import React, { useContext, useEffect, useState } from "react";
import Pitch from "./Pitch";

const TeamFormation = ({pitchData,setSelected, team, formations}) => {
  

  return (
    <div className="team-formation w-3/5 py-10 px-5">
      <div className="flex justify-between items-center ">
        <h2 className="team-title capitalize">{team?.name}</h2>
        <div>
          <select onChange={(e)=>setSelected(e.target.value)}  className=" px-3 select select-bordered w-full max-w-xs">
            {/* <option selected>{selected}</option> */}
            {
              formations?.map((item,index)=><option value={item} defaultValue={team?.formation===item}  key={index} >{item}</option>)
            }
          </select>
        </div>
      </div>
      <div className="formation-view py-[10px] mt-5">
        <Pitch team={team} pitchData={pitchData} />
      </div>
    </div>
  );
};

export default TeamFormation;
