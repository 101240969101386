import React, { createContext, useEffect, useState } from "react";

export const DataContext = createContext(null);

const assignPlayersData = [
  {
    team:"USA",
    players:[
      {
        id: "3_1_GK",
        playerName:"test",
        createdTeam:"USA",
        season: 2017,
        position: "GK",
        team: "USA",
        league: "ARG1",
       },
       {
        id: "2_3_LM",
        playerName:"test2",
        createdTeam:"USA",
        season: 2018,
        position: "GK2",
        team: "USA2",
        league: "ARG12",
       },
    ],
    benches:[
      {
        id:"p1",
        playerName:"test",
        createdTeam:"USA",
        season: 2017,
        position: "GK",
        team: "USA",
        league: "ARG1",
      },
    ]
  },
  {
    team:"ARG",
    players:[
      {
        id: "3_1_GK",
        playerName:"test",
        createdTeam: "ARG",
        season: 2017,
        position: "GK",
        team: "USA",
        league: "ARG1",
       },
       {
        id: "2_3_LM",
        playerName:"test2",
        createdTeam: "ARG",
        season: 2018,
        position: "GK2",
        team: "USA2",
        league: "ARG12",
       },
    ]
  },
]

const DataProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [playerShortListData, setPlayerShortListData] = useState([]);
  const [coachShortListData, setCoachShortListData] = useState([]);
  const [playerSlLoading, setPlayerSlLoading] = useState(true);
  const [coachSlLoading, setCoachSlLoading] = useState(true);
  const [formationData, setFormationData] = useState(null);
  const [assignedPlayers, setAssignedPlayers] = useState([]);
  const [teams, setTeams] = useState([]);

  const token = localStorage.getItem("token");
  const base_url = "https://api.fenida.org";

  useEffect(() => {
    setLoading(true);
    
    const getUser = async () => {
      await fetch(`${base_url}/api/user`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json) {
            setUser(json);
            setLoading(false);
          } else {
            setUser(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error) {
            setLoading(false);
            setUser(null);
          }
        });
    }

    if(token){
        getUser();
    }else {
      setUser(null);
      setLoading(false);
    }
  }, []);


  useEffect(() => {
    const getShortlist = async() =>{
        await fetch(`${base_url}/api/v1/player/shortlist?user_id=${user?.id}`, {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${token}`,
            }
          })
          .then(res=>res.json())
          .then(data=>{
            if(data?.length>0){
              setPlayerShortListData(data);
              setPlayerSlLoading(false);   
            }else if(data.length===0){
              setPlayerSlLoading(false);
            }
          })
    }
    if(user?.email ){
        getShortlist();
      // .catch(error=>{
      //   // if(error){
      //   //   alert("error from server");
      //   // }
      //   if(error){
      //     console.log("short list server error");
      //   }
      // })
    }else{
        
    }
  }, [user]);


  useEffect(() => {
    const getShortlist = async() =>{
        await fetch(`${base_url}/api/v1/coach/shortlist?user_id=${user?.id}`, {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${token}`,
            }
          })
          .then(res=>res.json())
          .then(data=>{
           
            if(data?.length>0){
              setCoachShortListData(data);
              setCoachSlLoading(false);   
            }else if(data.length===0){
              setCoachSlLoading(false);
            }
          })
    }
    if(user?.email ){
        getShortlist();
     
    }else{
        
    }
  }, [user]);

  // team fetch
  useEffect(() => {
    const getTeams = async() =>{
        await fetch(`${base_url}/api/v1/myteam?user_id=${user?.id}`, {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${token}`,
            }
          })
          .then(res=>res.json())
          .then(data=>{
           
            if(data?.length>0){
              setTeams(data)   
            }
          })
    }
    if(user?.email ){
        getTeams();
     
    }else{
        
    }
  }, [user]);

  // player fetch
  useEffect(() => {
    const getPlayers = async() =>{
        await fetch(`${base_url}/api/v1/team_players?user_id=${user?.id}`, {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${token}`,
            }
          })
          .then(res=>res.json())
          .then(data=>{
           
            if(data?.length>0){
              setAssignedPlayers(data)   
            }
          })
    }
    if(user?.email ){
        getPlayers();
     
    }else{
        
    }
  }, [user]);

  // fetch formation data
  useEffect(() => {
    const getFormationData = async() =>{
        await fetch(`${base_url}/api/v1/formations`, {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          }
        })
          .then(res=>res.json())
          .then(data=>{
           
            if(data?.length>0){
              setFormationData(data)   
            }
          })
    }
    if(user?.email ){
        getFormationData();
     
    }else{
        
    }
  }, [user]);


    // useEffect(() => {
    //     // setContentLoading(true);
    
    //     fetch(`${base_url}/api/v1/formations`)
    //       .then((res) => res.json())
    //       .then((data) => {
    //         setFormationData(data);
           
    //       })
    //       .catch((error) => {
    //         if (error) {
    //           alert("error from server");
    //         }
    //       });
    //   }, []);




  const value = {
    user,
    setUser,
    loading,
    setLoading,
    loginSuccess,
    setLoginSuccess,
    token,
    base_url,
    playerShortListData,
    setPlayerShortListData,
    playerSlLoading,
    coachShortListData,
    setCoachShortListData,
    coachSlLoading,
    formationData,
    setFormationData,
    assignedPlayers,
    setAssignedPlayers,
    teams,
    setTeams,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export default DataProvider;
