import { createBrowserRouter } from "react-router-dom";
import Main from "./../Layout/Main";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Players from "../Pages/Players/Players";
import Metric from "../Pages/Metric/Metric";
import Login from "../Pages/Login/Login";
import MetricCoach from "../Pages/MetricCoach/Metric";
import ShortLists from "../Pages/Shortlists/ShortLists";
import YourTeams from "../Pages/YourTeams/YourTeams";
import Coach from "../Pages/Coach/Coach";
import Team from "../Pages/Team/Team";
import Rankings from "../Pages/Rankings/Rankings";
import Coach2 from "../Pages/Coach2/Coach2";
import CoachShortlist from "../Pages/Coach-shortlist/CoachShortlist";
import Player from "../Pages/PlayerProfile/Player";
import PrivateRoute from "./PrivateRoute";
import ClubSearch from "../Pages/ClubSearch/ClubSearch";
import FinanceTeams from "../Pages/Finance/Team/FinanceTeams";
import FinanceTeam from "../Pages/Finance/Team/FinanceTeam";
import FinanceUploadFile from "../Pages/Finance/FinanceUploadFile/FinanceUploadFile";
import FinancePlayerInfo from "../Pages/Finance/FinancePlayerInfo/FinancePlayerInfo";
import Squad from "../Pages/Finance/Squad/Squad";
import PlayerProfile2 from "../Pages/PlayerProfile2/PlayerProfile2";
import CoachProfile from "../Pages/CoachProfile/CoachProfile";
import PlayerProfile3 from "../Pages/PlayerProfile3/PlayerProfile3";
import Survey from "../Pages/Survey/Survey";


export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <PrivateRoute><Dashboard /></PrivateRoute>,
      },
      {
        path: "/dashboard",
        element: <PrivateRoute><Dashboard/></PrivateRoute>,
      },
      {
        path: "/profile/:name_dob?",
        element: <PrivateRoute><PlayerProfile3 /></PrivateRoute>,
      },
      {
        path: "/profile1/:name_dob?",
        element: <PrivateRoute><Player /></PrivateRoute>,
      },
      {
        path: "/profile2/:name_dob?",
        element: <PrivateRoute><PlayerProfile2 /></PrivateRoute>,
      },
       {
        path: "/scout-search",
        element: <Survey/>,
      },
      // {
      //   path: "/profile-old",
      //   element: <PrivateRoute><Players /></PrivateRoute>,
      // },
      
      {
        path: "/market-search",
        element: <PrivateRoute><Metric /></PrivateRoute>,
      },
      // {
      //   path: "/coach-metric",
      //   element: <MetricCoach />,
      // },
      {
        path: "/short-lists",
        element: <PrivateRoute><ShortLists /></PrivateRoute>,
      },
      
      {
        path: "/club-search",
        element: <PrivateRoute><ClubSearch /></PrivateRoute>,
      },
      {
        path: "/teams",
        element: <PrivateRoute><YourTeams /></PrivateRoute>,
      },
      {
        path: "/team/:team",
        element: <PrivateRoute><Team /></PrivateRoute>,
      },
      {
        path: "/finance/teams",
        element: <PrivateRoute><FinanceTeams /></PrivateRoute>,
      },
      {
        path: "/finance/team/:team",
        element: <PrivateRoute><FinanceTeam /></PrivateRoute>,
      },
      {
        path: "/finance/upload-file",
        element: <PrivateRoute><FinanceUploadFile /></PrivateRoute>,
      },
      {
        path: "/finance/player-info",
        element: <PrivateRoute><FinancePlayerInfo /></PrivateRoute>,
      },
      {
        path: "/finance/squad",
        element: <PrivateRoute><Squad /></PrivateRoute>,
      },
      {
        path: "/rankings",
        element: <PrivateRoute><Rankings /></PrivateRoute>,
      },
      {
        path: "/coach-metric",
        element: <PrivateRoute><Coach2 /></PrivateRoute>,
      },
      {
        path: "/coach-shortlists",
        element: <PrivateRoute><CoachShortlist /></PrivateRoute>,
      },
      {
        path: "/coach-search/:coach?",
        element: <PrivateRoute><CoachProfile /></PrivateRoute>,
      },
      // {
      //   path: "/coach",
      //   element: <PrivateRoute><CoachProfile /></PrivateRoute>,
      // },
      // {
      //   path: "/coach",
      //   element: <PrivateRoute><Coach /></PrivateRoute>,
      // },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
