import React, { useEffect, useState } from "react";
import arrow from "../../../../Images/arrow.png";

const Position = ({
  positionSelected,
  setPositionSelected,
  season,
  seasonSelected,
  setLeagueSelected,
  setTeamSelected,
  finalData,
  setFirstClick,
  setPlayerInfo,
}) => {
  const [positionToggle, setPositionToggle] = useState(false);
  const [positionItems, setPositionItems] = useState([]);

  // console.log(finalData);
// console.log(season);
  // get position list of player
  useEffect(() => {
    if (finalData.length) {
      const positions = [];
      finalData.forEach((item) => {
        // positions.push(item.position) ;
        if (item.season === season) {
          positions.push(item.position);
          // console.log('season:'+ item.season, 'position:' + item.position);
        }
      });
      const arrData = Array.from(new Set(positions));
      const positionFinalItems = arrData.filter(
        (data) => data !== positionSelected
      );
      setPositionItems(arrData);
    }
  }, [finalData, seasonSelected]);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        !e.target.closest(".position-dropdown") &&
        !e.target.closest(".position-btn")
      ) {
        setPositionToggle(false);
      }
    });
  }, []);
  const setData = (position) => {
    let filterNeeded;

    filterNeeded = finalData?.filter(
      (data) => data?.position === position && data?.season === season
    );
console.log(filterNeeded);
    if (filterNeeded.length) {
      const newestSeasonObj = filterNeeded?.find((data) =>
        Math.max.apply(data.season)
      );

      const arrByLatestSeason = filterNeeded?.filter(
        (data) => data?.season === newestSeasonObj?.season
      );
      const arrByMaxMins = arrByLatestSeason?.filter((data) =>
        Math.max.apply(data?.minutes)
      );
      const player = arrByMaxMins[0];

      setPlayerInfo(player);

      setLeagueSelected(player?.league);
      setTeamSelected(player?.team_short_name);
    }
  };
  return (
    <div className="relative mt-3 ">
      <button
        onClick={() => setPositionToggle(!positionToggle)}
        className="w-full position-btn input-border p-3 rounded-lg outline-none flex justify-between items-center bg-base-100"
      >
        <p>{positionSelected === undefined ? "position" : positionSelected}</p>
        <img src={arrow} alt="" />
      </button>

      {positionToggle && (
        <div className="absolute max-h-[250px] overflow-y-scroll position-dropdown mt-1 w-full bg-base-100 shadow-lg rounded-lg  z-50 border">
          {positionItems?.map((item, index) => (
            <p
              key={index}
              onClick={() => {
                setPositionSelected(item);
                setFirstClick(false);
                setPositionToggle(false);
                setData(item);
              }}
              className="cursor-pointer p-1.5 hover:bg-[#F7F7F8] duration-300"
            >
              {item}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Position;
