import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Images/logo.png";
import login from "../../Images/login.jpg";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookSquare } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import { DataContext } from "../../Context/DataProvider";
import contentLoader from "../../Images/loader.gif";


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);

  const {
    loading,
    setUser,
    user,
    base_url,
    token,
    setLoginSuccess,
  } = useContext(DataContext);
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/";

//   useEffect(() => {
//     if (user && user.email) {
       
//       navigate(from, { replace: true });
//       console.log('Ami navigate');
//     }
//   }, [user]);

  

  const handleLogin = (e) => {
    e.preventDefault();
    setError(null);
    setLoginLoading(true);
    
    fetch(`${base_url}/api/login`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
           if(data?.token){
            setUser(data?.user);
            localStorage.setItem("token", data.token);
            setLoginLoading(false);
            setEmail("");
            setPassword("");
           }
           if(data?.message){
            setError(data.message);
            setLoginLoading(false);
           }
      })
      .catch(error=>{
        if(error){
          console.log(error);
        }
      })
  };
  if (user && user.email) {   
    navigate(from, { replace: true });
  }
  return (
    <section className="text-neutral">
      <header>
        <div className="grid grid-cols-2 h-[76px]">
          {/* Brand/logo */}
          <Link
            to="/"
            className="flex gap-2 items-center h-[76px] pl-10 2xl:pl-[128px]"
          >
            <img src={logo} alt="" className="w-[170px]" />
          </Link>

          <div className="lg:bg-gray-50 flex justify-end items-center pr-10 2xl:pr-[128px]">
            <Link>Login</Link>
          </div>
        </div>
      </header>

      <div className="grid lg:grid-cols-2 min-h-screen">
        <div className="hidden lg:block pl-10 2xl:pl-[170px]">
          <div className="mt-16">
            <div className="relative">
              <img
                src={login}
                alt=""
                className="w-[70%] xl:w-[55%] h-[600px] rounded-br-[100px]"
              />

              <div className="w-[300px] xl:w-[350px] bg-base-100 shadow-xl p-5 absolute left-[28%] bottom-24 rounded-xl">
                <div className="relative mb-4">
                  <button className="w-full input-border px-3 py-2 rounded-lg flex justify-between items-center bg-base-100">
                    <p className="font-medium text-neutral-content">
                      Start typing player name
                    </p>
                    <MdKeyboardArrowDown className="text-xl" />
                  </button>
                </div>

                <div className="relative mb-4">
                  <button className="w-full input-border px-3 py-2 rounded-lg flex justify-between items-center bg-base-100">
                    <p className="font-medium text-neutral-content">
                      Player Position
                    </p>
                    <MdKeyboardArrowDown className="text-xl" />
                  </button>
                </div>

                <div className="relative mb-4">
                  <button className="w-full input-border px-3 py-2 rounded-lg flex justify-between items-center bg-base-100">
                    <p className="font-medium text-neutral-content">Location</p>
                    <MdKeyboardArrowDown className="text-xl" />
                  </button>
                </div>

                <div>
                  <button className="w-full input-border px-3 py-2 rounded-lg bg-primary">
                    <p className="font-medium text-base-100 text-lg">search</p>
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-20">
              <h1 className="text-xl xl:text-[26px] 2xl:text-[32px] font-medium">
                Apply State-of-the-Art Decision-Technology
              </h1>
            </div>
          </div>
        </div>

        <div className="bg-gray-50 pb-4">
          <div className="mt-8 lg:mt-20 lg:flex justify-center items-center w-[90%] sm:w-[60%] lg:w-[85%] mx-auto xl:w-[600px]">
            <div className="sm:w-full md:w-4/5 mx-auto">
              <h6 className="text-2xl xl:text-[33px] font-semibold text-neutral/90">
                Welcome Back
              </h6>
              <p className="mt-5 font-medium text-[17px] text-neutral/60">
                Sign in your account
              </p>

              <div className="text-red-700 mt-8 text-base ">
                {error && error}
              </div>

              <form onSubmit={handleLogin} className="mt-10">
                <div>
                  <label htmlFor="email" className="text-neutral-content">
                    Your Email
                  </label>
                  <div>
                    <input
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      className="w-full border border-neutral-content focus:border-neutral px-4 py-3 rounded-lg outline-none bg-transparent mt-2"
                    />
                  </div>
                </div>

                <div className="mt-8">
                  <label htmlFor="email" className="text-neutral-content">
                    Password
                  </label>
                  <div>
                    <input
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      className="w-full border border-neutral-content focus:border-neutral px-4 py-3 rounded-lg outline-none bg-transparent mt-2"
                    />
                  </div>
                </div>

                <div className="mt-4 flex justify-between items-center">
                  <div className="w-3/5">
                    <div className="form-control">
                      <label className="label justify-start gap-3 cursor-pointer">
                        <input
                          type="checkbox"
                          className="checkbox checkbox-primary checkbox-sm"
                        />
                        <span>Remember me</span>
                      </label>
                    </div>
                  </div>
                  <div className="text-neutral-content">
                    <Link>Forgot Password</Link>
                  </div>
                </div>

                <div className="mt-6">
                  <button disabled={loginLoading}  className={`w-full px-4 py-3 rounded-lg text-base-100 text-lg ${loginLoading && !error ? 'bg-slate-500' : 'bg-primary'}`}>
                  {loginLoading && !error ? 'Loading...' : 'Login'}
                  </button>
                  
                </div>
              </form>

              {/* <div className="divider mt-8 lg:mt-14 text-neutral-content">
                Instant Login
              </div>

              <div className="grid sm:grid-cols-2 gap-4 mt-8 lg:mt-14 text-sm xl:text-lg">
                <button className="w-full shadow-lg border bg-base-100 px-4 py-3 rounded-lg text-neutral-content flex gap-2 items-center">
                  <FcGoogle className="text-2xl" />
                  Continue with Google
                </button>
                <button className="w-full shadow-lg border bg-base-100 px-2 xl:px-4 py-3 rounded-lg text-neutral-content flex gap-2 items-center">
                  <FaFacebookSquare className="text-primary text-3xl" />
                  Continue with Facebook
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
