import React, { useContext, useEffect, useState } from "react";
import ProfileInfo from "./ProfileInfo";
import HistoryGraph from "./HistoryGraph";
import { Link, useParams } from "react-router-dom";
import { debounce } from "lodash";
import { BiSearch } from "react-icons/bi";
import contentLoader from "../../Images/loader.gif";
import { DataContext } from "../../Context/DataProvider";
import './CoachProfile.css';

const CoachProfile = () => {
  const [searchedText, setSearchedText] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [coaches, setCoaches] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [coachInfo, setCoachInfo] = useState(null);
  const [contentLoading, setContentLoading] = useState(false);
  const [coachName, setCoachName] = useState(null);
  const [stats, setStats] = useState(null);

  const { base_url, token } = useContext(DataContext);
  const { coach } = useParams();

  useEffect(() => {
    if (searchedText !== "") {
      setSearchLoading(true);
      fetch(`${base_url}/api/v1/coach/find?keywords=${searchedText}`, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setCoaches(data?.data);
          setSearchLoading(false);
          setNotFound(false);
        });
    } else {
      setSearchLoading(false);
    }
  }, [searchedText, token]);

  useEffect(()=>{
    if(coachName || coach ){
    setContentLoading(true);
    fetch(`${base_url}/api/v1/coach/details?coach=${coach}`, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>{
      const sortedData = data?.sort((a, b) => Number(b?.season) - Number(a?.season));
      console.log(sortedData);
      setCoachInfo(sortedData[0]);
      setStats(sortedData);
      setContentLoading(false);
    })
    }

  },[coachName, coach, token])

  const handleCoachDetail = (coach) => {
    setCoachName(coach);
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        !e.target.closest(".coach-list") &&
        !e.target.closest(".search-box")
      ) {
        setSearchDropdown(false);
      }
    });
  }, []);

  useEffect(() => {
    if (searchedText === "") {
      setCoaches(null);
    }
  }, [searchedText]);

  const text = (e) => {
    setSearchedText(e.target.value);
    setSearchDropdown(true);
  };
  const debounceOnChange = debounce(text, 400);

  if (contentLoading) {
    return (
      <div
        id="preloader"
        className="d-flex align-items-center justify-content-center"
      >
        <span class="text-center">
          <img
            className="mx-auto d-block"
            width="300"
            alt="loader"
            src={contentLoader}
          />
        </span>
      </div>
    );
  }

  let content;
  if (searchLoading && coaches === null && searchedText.length > 0) {
    content = <progress className="progress loading w-56"></progress>;
  }

  if (coaches?.length === 0 && searchedText.length > 0) {
    content = <p className="not-found">No data found !</p>;
  }
  
  if (
    coaches?.length > 0 &&
    !searchLoading &&
    coaches !== null &&
    searchedText.length > 0 &&
    searchDropdown === true
  ) {
    content = (
      <ul className=" w-[84%] md:w-[28.5%] min-[1920px]:w-[20.8%] mt-4 absolute border rounded-[15px]  max-h-[350px] overflow-y-scroll z-50 shadow-lg bg-base-100 p-2 ">
        <h6 className="text-xs text-neutral-content mt-2 mb-1 ml-3">PLAYERS</h6>
        {coaches?.map((coach, index) => (
          <li key={index} onClick={() => handleCoachDetail(coach?.coach)}>
            <div className="p-3 pt-2">
              <div
                // onClick={handleNavigateProfile}
                className="text-sm cursor-pointer"
              >
                <Link to={`/coach-search/${coach?.coach}`}>
                  <h6 className="text-semibold">{coach?.coach}</h6>
                  <p className="text-neutral-content text-medium">
                    {coach.age} yr • {coach.team}
                  </p>
                </Link>
                
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className=" flex flex-col md:flex-row gap-5 pt-5 md:pt-10 pb-5 md:pb-10 px-5 md:px-10 min-h-screen">
      <div className="basis-full md:basis-[40%]">
        {/* search bar */}
        <div className={`relative w-full md:w-2/3 text-sm `}>
          <input
            type="text"
            name=""
            onChange={debounceOnChange}
            className="w-full input-border p-3 pl-10 rounded-lg outline-none"
            placeholder="Search for a coach"
          />

          <div className="absolute cursor-pointer top-4 left-4 text-lg">
            <BiSearch />
          </div>
        </div>
        {content}
        
        {
          coachInfo && <ProfileInfo coachInfo={coachInfo} stats={stats} />
        }
      </div>
      <div className="basis-full hidden md:block md:basis-[60%] ">
        {coachInfo && <HistoryGraph player={coach} /> }
      </div>
    </div>
  );
};

export default CoachProfile;
