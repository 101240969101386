import React, { useEffect, useState } from "react";

const positions = [
    "Forward (CF)",
    "Forward (Wing)",
    "Midfield (Center, Offensive)",
    "Midfield (Defensive)",
    "Defenders (Central)",
    "Defender (Wide)",
    "Goalkeepers",
];

const Positions = ({
    positionSelected,
    setPositionSelected,
    setFilteredabbr,
    setFormValues,
    setPlayerHistory,
    // setSeasonSelected,
    // setTeamSelected,
    setPage,
}) => {
    const [positionToggle, setPositionToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoad, setIsLoad] = useState(true);

    useEffect(() => {
        window.addEventListener("click", (e) => {
            if (!e.target.closest(".position-btn")) {
                setPositionToggle(false);
            }
        });
    }, []);

    const handleClick = () => {
        setPositionToggle(!positionToggle);
    };

    const allabbr = [
        {
            position: "Forward (CF)",
            fullname: "First Touch",
            shortName: "FT",
            apikey: "first_touch",
            min_value: "ft_min",
            max_avlue: "ft_max",
        },
        {
            position: "Forward (CF)",
            fullname: "Duel Strength",
            shortName: "DS",
            apikey: "duel_strength",
            min_value: "ds_min",
            max_avlue: "ds_max",
        },
        {
            position: "Forward (CF)",
            fullname: "Hold Up",
            shortName: "HU",
            apikey: "hold_up",
            min_value: "hu_min",
            max_avlue: "hu_max",
        },
        {
            position: "Forward (CF)",
            fullname: "Dribbling Ability",
            shortName: "DA",
            apikey: "dribbling_ability",
            min_value: "da_min",
            max_avlue: "da_max",
        },
        {
            position: "Forward (CF)",
            fullname: "Counter-Press",
            shortName: "CP",
            apikey: "counter_press",
            min_value: "cp_min",
            max_avlue: "cp_max",
        },
        {
            position: "Forward (CF)",
            fullname: "Shooting Technique",
            shortName: "ST",
            apikey: "shooting_technique",
            min_value: "st_min",
            max_avlue: "st_max",
        },
        {
            position: "Forward (CF)",
            fullname: "Tracking Back",
            shortName: "TB",
            apikey: "tracking_back",
            min_value: "tb_min",
            max_avlue: "tb_max",
        },
        {
            position: "Forward (CF)",
            fullname: "First Ball",
            shortName: "FB",
            apikey: "first_ball",
            min_value: "fb_min",
            max_avlue: "fb_max",
        },
        {
            position: "Forward (CF)",
            fullname: "Crossing Positioning",
            shortName: "CRP",
            apikey: "crossing_positioning",
            min_value: "crp_min",
            max_avlue: "crp_max",
        },
        {
            position: "Forward (CF)",
            fullname: "Get on Ball from Crosses",
            shortName: "GOB",
            apikey: "get_on_ball_from_crosses",
            min_value: "gob_min",
            max_avlue: "gob_max",
        },
        {
            position: "Forward (Wing)",
            fullname: "First Touch",
            shortName: "FT",
            apikey: "first_touch",
            min_value: "ft_min",
            max_avlue: "ft_max",
        },
        {
            position: "Forward (Wing)",
            fullname: "Duel Strength",
            shortName: "DS",
            apikey: "duel_strength",
            min_value: "ds_min",
            max_avlue: "ds_max",
        },
        {
            position: "Forward (Wing)",
            fullname: "Hold Up",
            shortName: "HU",
            apikey: "hold_up",
            min_value: "hu_min",
            max_avlue: "hu_max",
        },
        {
            position: "Forward (Wing)",
            fullname: "Dribbling Ability",
            shortName: "DA",
            apikey: "dribbling_ability",
            min_value: "da_min",
            max_avlue: "da_max",
        },
        {
            position: "Forward (Wing)",
            fullname: "Counter-Press",
            shortName: "CP",
            apikey: "counter_press",
            min_value: "cp_min",
            max_avlue: "cp_max",
        },
        {
            position: "Forward (Wing)",
            fullname: "Shooting Technique",
            shortName: "ST",
            apikey: "shooting_technique",
            min_value: "st_min",
            max_avlue: "st_max",
        },
        {
            position: "Forward (Wing)",
            fullname: "Tracking Back",
            shortName: "TB",
            apikey: "tracking_back",
            min_value: "tb_min",
            max_avlue: "tb_max",
        },
        {
            position: "Forward (Wing)",
            fullname: "Dribbling Variaton",
            shortName: "DV",
            apikey: "dribbling_variation",
            min_value: "dv_min",
            max_avlue: "dv_max",
        },
        {
            position: "Forward (Wing)",
            fullname: "Crossing Technique",
            shortName: "CT",
            apikey: "crossing_technique",
            min_value: "ct_min",
            max_avlue: "ct_max",
        },
        {
            position: "Forward (Wing)",
            fullname: "Pressing Wide",
            shortName: "PW",
            apikey: "pressing_wide",
            min_value: "pw_min",
            max_avlue: "pw_max",
        },
        {
            position: "Midfield (Center, Offensive)",
            fullname: "First Touch",
            shortName: "FT",
            apikey: "first_touch",
            min_value: "ft_min",
            max_avlue: "ft_max",
        },
        {
            position: "Midfield (Center, Offensive)",
            fullname: "Scanning Frequency",
            shortName: "SF",
            apikey: "scanning_frequency",
            min_value: "sf_min",
            max_avlue: "sf_max",
        },
        {
            position: "Midfield (Center, Offensive)",
            fullname: "Progressive Touch",
            shortName: "PT",
            apikey: "progressive_touch",
            min_value: "pt_min",
            max_avlue: "pt_max",
        },
        {
            position: "Midfield (Center, Offensive)",
            fullname: "Under Pressure",
            shortName: "UP",
            apikey: "under_pressure",
            min_value: "up_min",
            max_avlue: "up_max",
        },
        {
            position: "Midfield (Center, Offensive)",
            fullname: "Defensive Set-Plays",
            shortName: "DSP",
            apikey: "defensive_set_plays",
            min_value: "dsp_min",
            max_avlue: "dsp_max",
        },
        {
            position: "Midfield (Center, Offensive)",
            fullname: "Pressing Intensity",
            shortName: "PI",
            apikey: "pressing_intensity",
            min_value: "pi_min",
            max_avlue: "pi_max",
        },
        {
            position: "Midfield (Center, Offensive)",
            fullname: "Duel Strength",
            shortName: "DS",
            apikey: "duel_strength",
            min_value: "ds_min",
            max_avlue: "ds_max",
        },
        {
            position: "Midfield (Center, Offensive)",
            fullname: "Shooting Technique",
            shortName: "ST",
            apikey: "shooting_technique",
            min_value: "st_min",
            max_avlue: "st_max",
        },
        {
            position: "Midfield (Center, Offensive)",
            fullname: "Passing Creativity",
            shortName: "PC",
            apikey: "passing_creativity",
            min_value: "pc_min",
            max_avlue: "pc_max",
        },
        {
            position: "Midfield (Defensive)",
            fullname: "First Touch",
            shortName: "FT",
            apikey: "first_touch",
            min_value: "ft_min",
            max_avlue: "ft_max",
        },
        {
            position: "Midfield (Defensive)",
            fullname: "Scanning Frequency",
            shortName: "SF",
            apikey: "scanning_frequency",
            min_value: "sf_min",
            max_avlue: "sf_max",
        },
        {
            position: "Midfield (Defensive)",
            fullname: "Progressive Touch",
            shortName: "PT",
            apikey: "progressive_touch",
            min_value: "pt_min",
            max_avlue: "pt_max",
        },
        {
            position: "Midfield (Defensive)",
            fullname: "Under Pressure",
            shortName: "UP",
            apikey: "under_pressure",
            min_value: "up_min",
            max_avlue: "up_max",
        },
        {
            position: "Midfield (Defensive)",
            fullname: "Defensive Set Plays",
            shortName: "DSP",
            apikey: "defensive_set_plays",
            min_value: "dsp_min",
            max_avlue: "dsp_max",
        },
        {
            position: "Midfield (Defensive)",
            fullname: "Pressing Intensity",
            shortName: "PI",
            apikey: "pressing_intensity",
            min_value: "pi_min",
            max_avlue: "pi_max",
        },
        {
            position: "Midfield (Defensive)",
            fullname: "Duel Strength",
            shortName: "DS",
            apikey: "duel_strength",
            min_value: "ds_min",
            max_avlue: "ds_max",
        },
        {
            position: "Midfield (Defensive)",
            fullname: "Tracking Runners",
            shortName: "TR",
            apikey: "tracking_runners",
            min_value: "tr_min",
            max_avlue: "tr_max",
        },        
        {
            position: "Defenders (Central)",
            fullname: "First Touch",
            shortName: "FT",
            apikey: "first_touch",
            min_value: "ft_min",
            max_avlue: "ft_max",
        },
        {
            position: "Defenders (Central)",
            fullname: "Offensive Set-Plays",
            shortName: "OSP",
            apikey: "offensive_set_plays",
            min_value: "osp_min",
            max_avlue: "osp_max",
        },
        {
            position: "Defenders (Central)",
            fullname: "Passing Technique",
            shortName: "PT",
            apikey: "passing_technique",
            min_value: "pt_min",
            max_avlue: "pt_max",
        },
        {
            position: "Defenders (Central)",
            fullname: "Step Up",
            shortName: "SU",
            apikey: "step_up",
            min_value: "su_min",
            max_avlue: "su_max",
        },
        {
            position: "Defenders (Central)",
            fullname: "Defending Wide",
            shortName: "DW",
            apikey: "defending_wide",
            min_value: "dw_min",
            max_avlue: "dw_max",
        },
        {
            position: "Defenders (Central)",
            fullname: "Box Defending",
            shortName: "BD",
            apikey: "box_defending",
            min_value: "bd_min",
            max_avlue: "bd_max",
        },
        {
            position: "Defenders (Central)",
            fullname: "Defensive Set Plays",
            shortName: "DSP",
            apikey: "defensive_set_plays",
            min_value: "dsp_min",
            max_avlue: "dsp_max",
        },
        {
            position: "Defenders (Central)",
            fullname: "Defending Space",
            shortName: "DSB",
            apikey: "defending_space",
            min_value: "dsb_min",
            max_avlue: "dsb_max",
        },
        {
            position: "Defenders (Central)",
            fullname: "Duel Strength",
            shortName: "DS",
            apikey: "duel_strength",
            min_value: "ds_min",
            max_avlue: "ds_max",
        },
        {
            position: "Defender (Wide)",
            fullname: "First Touch",
            shortName: "FT",
            apikey: "first_touch",
            min_value: "ft_min",
            max_avlue: "ft_max",
        },
        {
            position: "Defender (Wide)",
            fullname: "Progressive Touch",
            shortName: "PT",
            apikey: "progressive_touch",
            min_value: "pt_min",
            max_avlue: "pt_max",
        },
        {
            position: "Defender (Wide)",
            fullname: "Link-Up Play",
            shortName: "LP",
            apikey: "link_up_play",
            min_value: "lp_min",
            max_avlue: "lp_max",
        },
        {
            position: "Defender (Wide)",
            fullname: "Final Delivery",
            shortName: "FD",
            apikey: "final_delivery",
            min_value: "fd_min",
            max_avlue: "fd_max",
        },
        {
            position: "Defender (Wide)",
            fullname: "Dribbling Ability",
            shortName: "DA",
            apikey: "dribbling_ability",
            min_value: "da_min",
            max_avlue: "da_max",
        },
        {
            position: "Defender (Wide)",
            fullname: "Defending Space",
            shortName: "DSB",
            apikey: "defending_space",
            min_value: "dsb_min",
            max_avlue: "dsb_max",
        },
        {
            position: "Defender (Wide)",
            fullname: "One-vs-One",
            shortName: "OVO",
            apikey: "one_vs_one",
            min_value: "ovo_min",
            max_avlue: "ovo_max",
        },
        {
            position: "Defender (Wide)",
            fullname: "Back Post",
            shortName: "BP",
            apikey: "back_post",
            min_value: "bp_min",
            max_avlue: "bp_max",
        },
        {
            position: "Defender (Wide)",
            fullname: "Duel Strength",
            shortName: "DS",
            apikey: "duel_strength",
            min_value: "ds_min",
            max_avlue: "ds_max",
        },
    ];


    // const uniqueArray = Object.values(allabbr.reduce((acc, curr) => {
    //     acc[curr.apikey] = curr;
    //     return acc;
    // }, {}));
    
    // console.log(uniqueArray);
    

    const handlePositionSelected = (position) => {
        setLoading(false);
        setPositionSelected(position);
        // setSeasonSelected("select season");
        // setTeamSelected("select team");
        setIsLoad(false);
        setPage(1);
        setFormValues({});
        setPlayerHistory([])
        const filtered = allabbr.filter(abbr => abbr.position === position);
        setFilteredabbr(filtered);
        
    };

    return (
        <div className="mt-3">
            <h6 className="text-sm">Position</h6>
            {/* {allabbr?.map((item, index) => (
                    <span key={index}>{item.min_value}={'${'}formValues?.{item.min_value}{'}'}&{item.max_avlue}={'${'}formValues?.{item.max_avlue}{'}'}&</span>
                ))} */}
            <div className="relative mt-2">
                <button
                    onClick={handleClick}
                    className="w-full xl:w-full text-sm input-border capitalize p-2 rounded-lg outline-none position-btn flex  bg-base-100"
                >
                    {loading && isLoad ? (
                        <svg
                            className="animate-spin mx-auto h-5 w-5 text-slate-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    ) : positionSelected.length > 0 ? (
                        positionSelected
                    ) : (
                        "Select Position"
                    )}
                </button>
                
                {positionToggle && (
                    <div className="absolute max-h-[300px] overflow-y-scroll league-dropdown w-full bg-base-100 shadow-lg mt-1 rounded-lg  z-50 border">
                        {positions.map((item, index) => (
                            <p
                                key={index}
                                onClick={() => handlePositionSelected(item)}
                                className="cursor-pointer p-1.5 text-sm hover:bg-[#F7F7F8] duration-300"
                            >
                                {item}
                            </p>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Positions;
